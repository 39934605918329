<template>
  <VTable
    :head-row="tableHead"
    :rows="tableList"
    :is-checkbox="false"
  />
</template>

<script>
import VTable from '@/components/ui/table/VTable'
import { mocksChangelogTableList } from '@/mocks/table'

export default {
  setup () {
    const tableHead = [
      {
        name: 'Дата и время',
        value: 'date',
        filter: true
      },
      {
        name: 'Событие',
        value: 'event',
        filter: true
      }
    ]
    const tableList = [...mocksChangelogTableList]

    return {
      tableHead,
      tableList
    }
  },
  components: {
    VTable
  }
}
</script>

<style scoped>

</style>
