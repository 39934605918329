export function setTableList (value, length = 10) {
  return Array.from({ length }).map((_, i) => {
    return {
      id: i + 1,
      ...value
    }
  })
}

export const mockTableHeadList = [
  {
    name: 'Наименование',
    value: 'name',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'Дата/время заявки',
    value: 'date',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'ФИО ответственного',
    value: 'fio',
    filter: true,
    decorator: {
      name: 'link'
    }
  }
]

export const mockTableHeadList5Column = [
  {
    name: '№ лота',
    value: 'numberLot',
    filter: true
  },
  {
    name: 'Наименование',
    value: 'name',
    filter: true
  },
  {
    name: 'Статус',
    value: 'status',
    filter: true
  },
  {
    name: 'Позиции',
    value: 'positions',
    filter: true
  },
  {
    name: 'Дата и время решения',
    value: 'date',
    filter: true
  }
]

const tableItem = {
  name: 'ООО "ТехПроХолод"',
  date: '11.12.2012 17:44:30',
  fio: 'ООО “Название компании”'
}
export const mockTableList = setTableList(tableItem)

const tableItem2 = {
  name: 'Петр Петров',
  position: 'Сотрудник отдела снабжения',
  login: '12345',
  email: 'info@mail.ru',
  company: 'ООО"ТехПроХолод"',
  act: 'Нет',
  date: '11.12.2012 17:44:00',
  numberLot: '1',
  status: 'Статус',
  positions: 'Позиции'
}
export const mockTableList5Column = setTableList(tableItem2)

const changelogValues = {
  data: '12.04.2022 13:20',
  event: 'Разнообразный и богатый опыт укрепление и развитие структуры играет важную роль в формировании позиций'
}

export const mocksChangelogTableList = setTableList(changelogValues)
